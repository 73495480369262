<template>
  <form v-if="debug" class="starfield-debug">
    <div>
      <label for="isHyperspace">
        <input id="isHyperspace" v-model="hyperspace" type="checkbox">Hyperspace
      </label>

      <label for="warpFactor">
        | Warp Factor
      </label>
      <input
        id="warpFactor"
        v-model="warpFactor"
        type="number"
        name="warpFactor"
        :placeholder="warpFactor"
        min="1"
        max="1000"
      >
    </div>

    <div>
      <label for="easing">Easing: </label>
      <input
        id="easing"
        v-model="easing"
        type="number"
        name="easing"
        :placeholder="easing"
        min="0"
        max="100"
        step="1"
      >
    </div>

    <div>
      <label for="quantity">Number of stars: </label>
      <input
        id="quantity"
        v-model="quantity"
        type="number"
        name="quantity"
        :placeholder="quantity"
        min="0"
        max="8192"
        step="1"
        @change="reset"
      >
    </div>

    <div>
      <label for="starColor">Star color:</label>
      <input
        v-model="starColor"
        type="color"
        @change="reset"
      >
    </div>

    <div>
      <label for="bgColor">Background color:</label>
      <input v-model="bgColor" type="color">
    </div>

    <div>
      <label for="speed">Speed: </label>
      <input
        id="speed"
        v-model="speed"
        type="number"
        name="speed"
        :placeholder="speed"
        min="-100"
        max="100"
        step="1"
      >
    </div>

    <div
      style="margin-top: 20px; padding-top: 10px; border-top: 1px solid rgba(255, 255, 255, 0.25); font-size: 0.8em"
    >
      <input
        type="button"
        value="Start"
        style="color: #000"
        :disabled="state.running"
        @click="start()"
      > |
      <input
        type="button"
        value="Stop"
        style="color: #000"
        :disabled="!state.running"
        @click="stop()"
      > |
      <input
        type="button"
        value="Reset"
        style="color: #000"
        @click="reset()"
      >
    </div>
  </form>
</template>

<script>
import { useStarfieldStore } from "@/stores/starfield-store";
import { mapState, mapWritableState } from "pinia";

export default {
  name: "StarfieldDebug",
  computed: {
    ...mapWritableState(useStarfieldStore, [
      "debug",
      "hyperspace",
      "warpFactor",
      "easing",
      "quantity",
      "starColor",
      "bgColor",
      "speed",
      "state",
    ]),
    ...mapState(useStarfieldStore, ["compColors"]),
  },
  emits: ["stop", "start", "reset"],
  methods: {
    stop() {
      this.$emit("stop");
    },
    start() {
      this.$emit("start");
    },
    reset() {
      this.$emit("reset");
    },
  },
};
</script>


<style scoped>
.starfield-debug {
  position: absolute;
  left: 20px;
  top: 20px;
  color: #fff;
  font-size: 16px;
  z-index: 1;
  line-height: 2em;
  font-family: 'Helvetica Neue', Helvetica, arial, sans-serif;
  font-weight: 300;
}
</style>
