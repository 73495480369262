import { defineStore } from "pinia";
import { $axios } from "@bloglovin/vue-framework";
import { useGeneralStore } from "@/stores/general-store";
import R2D2Failure from '@/audio/r2-d2-01.mp3';
import R2D2Success from '@/audio/r2-d2-22.mp3';
import R2D2Trigger from '@/audio/r2-d2-07.mp3';

export const usePullRequestStore = defineStore("pull-request-store", {
  state: () => ({
    loadingOpenPullRequests: false,
    loadingMergedPullRequests: false,
    loadingMergePullRequestNumber: -1,
    loadingMergeApprovedPullRequest: false,
    openPullRequests: {},
    mergedPullRequests: [],
    currentBuildImageStatus: "",
    currentDeployStagingStatus: "",
    currentDeployProductionStatus: "",
    statusCheckIntervalId: null,
    repositoryName: "",
    failureAudio: new Audio(R2D2Failure),
    successAudio: new Audio(R2D2Success),
    triggerAudio: new Audio(R2D2Trigger),
  }),
  getters: {
    orderedOpenPullRequests() {
      return Object.values(this.openPullRequests).sort((pullRequestA, pullRequestB) => {
        return pullRequestA.number < pullRequestB.number;
      });
    },
    isRunningAction() {
      return this.isRunningBuildImage || this.isRunningDeployStaging || this.isRunningDeployProduction;
    },
    isRunningBuildImage() {
      return this.currentBuildImageStatus.startsWith("QUEUED") || this.currentBuildImageStatus.startsWith("RUNNING");
    },
    isRunningDeployStaging() {
      return this.currentDeployStagingStatus.startsWith("QUEUED") || this.currentDeployStagingStatus.startsWith("RUNNING");
    },
    isRunningDeployProduction() {
      return this.currentDeployProductionStatus.startsWith("QUEUED") || this.currentDeployProductionStatus.startsWith("RUNNING");
    },
    isDeployableAppType() {
      return useGeneralStore().getApp(this.repositoryName).type === "microservice";
    },
  },
  actions: {
    mergeApprovedPullRequest(pullRequestNumber) {
      if (this.loadingMergeApprovedPullRequest) {
        return;
      }
      this.loadingMergeApprovedPullRequest = true;
      this.loadingMergePullRequestNumber = pullRequestNumber;
      $axios.post("/pull-request/merge", {
        pull_request_number: pullRequestNumber,
        repository_name: this.repositoryName,
      }).then(response => {
        if (response.data.success) {
          delete this.openPullRequests[pullRequestNumber];
          this.mergedPullRequests = response.data.mergedPullRequests;
        }
        this.loadingMergePullRequestNumber = -1;
        this.loadingMergeApprovedPullRequest = false;
      });
    },
    fetchMergedPullRequests() {
      if (this.loadingMergedPullRequests) {
        return;
      }
      this.loadingMergedPullRequests = true;
      $axios.get(`/pull-request/merged/${this.repositoryName}`)
          .then(response => {
            this.mergedPullRequests = response.data.mergedPullRequests;
            this.loadingMergedPullRequests = false;
          });
    },
    fetchOpenPullRequests() {
      if (this.loadingOpenPullRequests) {
        return;
      }
      this.loadingOpenPullRequests = true;
      $axios.get(`/pull-request/open/${this.repositoryName}`)
          .then(response => {
            this.openPullRequests = response.data.openPullRequests;
            this.loadingOpenPullRequests = false;
          });
    },
    buildImage(mergedPullRequestSha) {
      this.triggerAudio.play();
      $axios.post(`/pull-request/build/${this.repositoryName}/sha/${mergedPullRequestSha}`)
          .then(response => {
            this.currentBuildImageStatus = response.data.status;
          });
    },
    deployToStaging(mergedPullRequestSha) {
      this.triggerAudio.play();
      $axios.post(`/pull-request/deploy/${this.repositoryName}/staging/sha/${mergedPullRequestSha}`)
          .then(response => {
            this.currentDeployStagingStatus = response.data.status;
          });
    },
    deployToProduction(mergedPullRequestSha) {
      this.triggerAudio.play();
      $axios.post(`/pull-request/deploy/${this.repositoryName}/production/sha/${mergedPullRequestSha}`)
          .then(response => {
            this.currentDeployProductionStatus = response.data.status;
          });
    },
    checkDeploymentStatus() {
      $axios.get(`/pull-request/deploy/${this.repositoryName}/status`)
          .then(response => {
            this.playSuccessOrFailureAudio(response.data.status);
            this.currentBuildImageStatus = response.data.status.build;
            this.currentDeployStagingStatus = response.data.status.staging;
            this.currentDeployProductionStatus = response.data.status.production;
          });
    },
    setRepositoryName(repositoryName) {
      this.repositoryName = repositoryName;
    },
    playSuccessOrFailureAudio(newStatuses) {
      if (this.currentBuildImageStatus.startsWith("RUNNING") && newStatuses.build.startsWith("COMPLETE")) {
        this.successAudio.play();
      }
      if (this.currentDeployStagingStatus.startsWith("RUNNING") && newStatuses.staging.startsWith("COMPLETE")) {
        this.successAudio.play();
      }
      if (this.currentDeployProductionStatus.startsWith("RUNNING") && newStatuses.production.startsWith("COMPLETE")) {
        this.successAudio.play();
      }
      if (this.currentBuildImageStatus.startsWith("RUNNING") && newStatuses.build.startsWith("FAILED")) {
        this.failureAudio.play();
      }
      if (this.currentDeployStagingStatus.startsWith("RUNNING") && newStatuses.staging.startsWith("FAILED")) {
        this.failureAudio.play();
      }
      if (this.currentDeployProductionStatus.startsWith("RUNNING") && newStatuses.production.startsWith("FAILED")) {
        this.failureAudio.play();
      }
    }
  },
});
