import "@bloglovin/vue-component-library/style.css";
import { createPinia } from "pinia";
import { init, axiosInit, AxiosPiniaPlugin } from "@bloglovin/vue-framework";
import pages from "@/pages";
import { useGeneralStore } from "@/stores/general-store";

import "@/styles/base.scss";
import { useStyleStore } from "@bloglovin/vue-component-library";
import BasicTheme from "@/themes/basic-theme";

axiosInit(window.blclient.data.JWT, window.blclient.data.links.api);

const initializationFunction = function(piniaInstance) {
  useStyleStore(piniaInstance).setTheme(BasicTheme);
  useGeneralStore(piniaInstance).setApps(window.blclient.data.apps);

};

let pinia = createPinia();
pinia.use(AxiosPiniaPlugin);

init(process.env.NODE_ENV, process.env.PROJECT_NAME, pages, pinia, initializationFunction);
