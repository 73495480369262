<template>
  <Starfield>
    <iColumn>
      <iRow justify-content="space-between">
        <iRow width="hug">
          <iHeading variant="accent" @click="returnToAppSelection">
            R2-D2
          </iHeading>
          <iHeading variant="subtle">
            /
          </iHeading>
          <iHeading variant="subtle">
            {{ currentApp.app_name.toLowerCase() }}
          </iHeading>
        </iRow>
        <iRow width="hug">
          <iHeading variant="accent" @click="logout">
            force quit
          </iHeading>
        </iRow>
      </iRow>
      <iRow width="hug">
        <iColumn>
          <iSelect
            v-model="selectedMergedPullRequest"
            :is-loading="loadingMergedPullRequests"
            :items="mergedPullRequests"
            text-field="display_title"
            value-field="number"
          />
        </iColumn>
        <iRow v-if="isDeployableAppType" width="hug">
          <iColumn>
            <iButton
              :disabled="!selectedMergedPullRequest"
              variant="secondary"
              @click="buildImage(selectedMergedPullRequest.merge_commit_sha)"
            >
              Build
            </iButton>
          </iColumn>
          <iColumn>
            <iButton
              :disabled="!selectedMergedPullRequest"
              variant="secondary"
              @click="deployToStaging(selectedMergedPullRequest.merge_commit_sha)"
            >
              Staging
            </iButton>
          </iColumn>
          <iColumn>
            <iButton
              :disabled="!selectedMergedPullRequest"
              variant="secondary"
              @click="deployToProduction(selectedMergedPullRequest.merge_commit_sha)"
            >
              Production
            </iButton>
          </iColumn>
        </iRow>
        <iColumn v-if="isDeployableAppType">
          <iRow>
            <iText variant="subtle">
              Build: {{ currentBuildImageStatus }}
            </iText>
          </iRow>
          <iRow>
            <iText variant="subtle">
              Staging: {{ currentDeployStagingStatus }}
            </iText>
          </iRow>
          <iRow>
            <iText variant="subtle">
              Production: {{ currentDeployProductionStatus }}
            </iText>
          </iRow>
        </iColumn>
      </iRow>
      <iDataTable :items="orderedOpenPullRequests" :is-loading="loadingOpenPullRequests" :fields="openPullRequestTableFields">
        <template #actions>
          <iButton variant="secondary" @click="fetchPullRequests()">
            Update
          </iButton>
        </template>
        <template #loading>
          <iRow>
            <iLoadingSpinner variant="light" />
          </iRow>
        </template>
        <template #td(title)="{data}">
          <iLink target="_blank" :href="data.pullRequestUrl">
            {{ data.title }} (#{{ data.number }})
          </iLink>
        </template>
        <template #td(user)="{data}">
          <iRow width="hug">
            <iImageContainer :height="20" :src="data.user.avatarUrl" />
            <iText variant="subtle">
              {{ data.user.username }}
            </iText>
          </iRow>
        </template>
        <template #td="{field, value}">
          <iRow width="hug">
            <iText :variant="getApprovedAndMergeableVariant(field, value)">
              {{ value }}
            </iText>
          </iRow>
        </template>
        <template #td(actions)="{data}">
          <template v-if="pullRequestIsMergeable(data)">
            <iButton
              :is-loading="loadingMergePullRequestNumber === data.number"
              loading-spinner-variant="light"
              @click="mergeApprovedPullRequest(data.number)"
            >
              Merge PR
            </iButton>
          </template>
        </template>
      </iDataTable>
    </iColumn>
  </Starfield>
</template>

<script>

import { mapActions, mapState } from "pinia";
import { usePullRequestStore } from "@/stores/pull-request-store";
import { useGeneralStore } from "@/stores/general-store";
import Starfield from "@/components/Starfield.vue";

export default {
  name: "Deploy",
  components: { Starfield },
  props: {
    repositoryName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedMergedPullRequest: null,
      statusCheckIntervalId: null,
    };
  },
  computed: {
    ...mapState(usePullRequestStore, [
      "loadingMergePullRequestNumber",
      "openPullRequests",
      "mergedPullRequests",
      "orderedOpenPullRequests",
      "loadingOpenPullRequests",
      "loadingMergedPullRequests",
      "currentDeployStagingStatus",
      "currentDeployProductionStatus",
      "currentBuildImageStatus",
      "isDeployableAppType",
    ]),
    ...mapState(useGeneralStore, [
      "apps",
    ]),
    currentApp() {
      return this.apps[this.repositoryName];
    },
    openPullRequestTableFields() {
      return [
        {
          "key": "title",
          "label": "Title",
        },
        {
          "key": "user",
          "label": "User",
        },
        {
          "key": "approved",
          "label": "Approved",
        },
        {
          "key": "lint",
          "label": "Lint",
        },
        {
          "key": "mergeable",
          "label": "Mergeable",
        },
        {
          "key": "draft",
          "label": "Draft",
        },
        {
          "key": "actions",
          "label": "Actions",
        },

      ];
    },
  },
  created() {
    this.setRepositoryName(this.repositoryName);
    this.fetchPullRequests();
    this.checkDeploymentStatus();
    this.statusCheckIntervalId = setInterval(() => {
      this.checkDeploymentStatus();
    }, 3000);
  },
  beforeUnmount() {
    clearInterval(this.statusCheckIntervalId);
  },
  methods: {
    ...mapActions(usePullRequestStore, [
      "setRepositoryName",
      "fetchMergedPullRequests",
      "fetchOpenPullRequests",
      "mergeApprovedPullRequest",
      "buildImage",
      "deployToStaging",
      "deployToProduction",
      "checkDeploymentStatus",
    ]),
    ...mapActions(useGeneralStore, [
      "logout",
    ]),
    fetchPullRequests() {
      this.fetchOpenPullRequests();
      this.fetchMergedPullRequests();
    },
    returnToAppSelection() {
      window.location.href = "/";
    },
    pullRequestIsMergeable(data) {
      return !data.draft && data.mergeable && data.approved && ["PASSED", "N/A"].includes(data.lint);
    },
    getApprovedAndMergeableVariant(field, value) {
      if (field.key === "draft") {
        if (value) {
          return "error";
        }
        return "success";
      }

      if (value === "FAILED" || !value) {
        return "error";
      }
      if (value === "PASSED" || value === true) {
        return "success";
      }
      return "subtle";
    },
  },
};
</script>
