// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.starfield-debug[data-v-632ece19] {
  position: absolute;
  left: 20px;
  top: 20px;
  color: #fff;
  font-size: 16px;
  z-index: 1;
  line-height: 2em;
  font-family: "Helvetica Neue", Helvetica, arial, sans-serif;
  font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/StarfieldDebug.vue","webpack://./StarfieldDebug.vue"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,UAAA;EACA,gBAAA;EACA,2DAAA;EACA,gBAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.starfield-debug {\n  position: absolute;\n  left: 20px;\n  top: 20px;\n  color: #fff;\n  font-size: 16px;\n  z-index: 1;\n  line-height: 2em;\n  font-family: 'Helvetica Neue', Helvetica, arial, sans-serif;\n  font-weight: 300;\n}\n",".starfield-debug {\n  position: absolute;\n  left: 20px;\n  top: 20px;\n  color: #fff;\n  font-size: 16px;\n  z-index: 1;\n  line-height: 2em;\n  font-family: \"Helvetica Neue\", Helvetica, arial, sans-serif;\n  font-weight: 300;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
